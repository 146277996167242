import { FC, useEffect, useState, useRef, useCallback, useMemo } from "react"
import { SizableText, Separator, Stack, View, H6, useMedia, ImageProps } from "tamagui"
import { ModulesLoader, ModulesLoaderSm } from "./ProgramsLoader"
import { Pressable } from "react-native"
import { useFeatureFlag } from "app/feature-flags/useFeatureFlag"
import { useFetchModules } from "app/hooks/useFetchModules"
import { useModuleStore } from "../../global-state/programStore"
import { useRouter } from "solito/router"
import { ProgramStatus } from "@my/api/src/types/programs"
import { ImageWithPlaceholder } from "@my/ui/src/placeholders/PlaceholderImage"
import { useFetchSubscriptions } from "../../hooks/useSubscriptions"
import { useSubscriptionStore } from "app/global-state/subscriptionsStore"
import { mixpanel, trackButtonClicked } from "app/telemetry"
import { Routes } from "app/routing"
import { useAuth } from "@my/api"

export const ProgramsList: FC = () => {
  const { isAuthenticated } = useAuth()
  const { sm, md, lg } = useMedia()
  const router = useRouter()
  const modulesUnpublishedFlag = useFeatureFlag("modules-view-unpublished", false)
  const { modules = [] } = useModuleStore()
  const { getSubscriptionByProperty } = useSubscriptionStore()

  const [isLoading, setIsLoading] = useState(false)
  const mountedRef = useRef(false)
  const [loadedImages, setLoadedImages] = useState<Record<number, boolean>>({})
  const batchUpdateRef = useRef<NodeJS.Timeout>()

  const pageName = useMemo(() => {
    return !isAuthenticated ? "GetHealthy" : "Explore"
  }, [isAuthenticated])

  mixpanel.trackOnMount({ page_name: pageName, logged_out: !isAuthenticated })

  // Memoize this to prevent recreation
  const loaderItems = useMemo(() => {
    if (sm) return 5
    if (md) return 2
    if (lg) return 3
    return 4
  }, [sm, md, lg])

  const loaderList = useMemo(() => Array.from(Array(loaderItems).keys()), [loaderItems])

  const fetchModules = useFetchModules()
  const fetchSubscriptions = useFetchSubscriptions()

  // Batch update loadedImages. Prevents a flood of re-renders.
  const handleImageLoad = useCallback((index: number) => {
    setLoadedImages((prev) => {
      // If this image is already marked as loaded, don't update
      if (prev[index]) return prev

      // Clear any existing timeout
      if (batchUpdateRef.current) {
        clearTimeout(batchUpdateRef.current)
      }

      // Set a new timeout to batch updates
      batchUpdateRef.current = setTimeout(() => {
        setLoadedImages((current) => ({ ...current, [index]: true }))
      }, 100) // Batch updates within 100ms

      return prev // Don't update state immediately
    })
  }, [])

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (batchUpdateRef.current) {
        clearTimeout(batchUpdateRef.current)
      }
    }
  }, [])

  useEffect(() => {
    // If we've already mounted, don't do anything.
    // This also means adding dependencies to the effect would be pointless
    if (mountedRef.current) return

    mountedRef.current = true
    ;(async () => {
      try {
        if (!mountedRef.current) return

        // We only want to show the loader if we have no modules fetched
        if (modules.length === 0) {
          setIsLoading(true)
        }

        // Always fetch modules in case there's something new
        await fetchModules()

        // The following is an authenticated endpoint
        if (isAuthenticated) {
          await fetchSubscriptions()
        }
      } finally {
        if (mountedRef.current) {
          setIsLoading(false)
        }
      }
    })()

    // Cleanup function to handle unmounting
    return () => {
      mountedRef.current = false
    }
  }, []) // Empty dependency array since we only want this to run once on mount

  const renderLoader = (key: number | string) => {
    return sm ? <ModulesLoaderSm key={key} /> : <ModulesLoader key={key} />
  }

  const SubscribedImageBanner = ({ moduleId }: { moduleId: string }) => {
    const subscription = getSubscriptionByProperty("moduleId", moduleId)

    if (!subscription) {
      return null
    }

    return (
      <View
        backgroundColor="rgba(0,0,0,0.6)"
        borderTopLeftRadius="$6"
        borderBottomRightRadius="$6"
        borderTopRightRadius={sm ? "$3" : 0}
        top={0}
        left={0}
        padding="$xs"
        position="absolute"
        maxWidth="100%"
      >
        <SizableText
          fontSize={sm ? "$3" : "$4"}
          fontWeight="400"
          color="$colorInverse"
          textTransform="uppercase"
          textWrap="nowrap"
          textOverflow="ellipsis"
        >
          Subscribed
        </SizableText>
      </View>
    )
  }

  const style = useMemo(
    () =>
      ({
        marginHorizontal: isAuthenticated ? undefined : "auto",
        width: isAuthenticated ? undefined : "100%",
        maxWidth: 1064,
      }) as const,
    [isAuthenticated],
  )

  // Memoize the modules rendering
  const renderedModules = useMemo(() => {
    return modules.map((module, index) => {
      const showModule =
        module.status !== ProgramStatus.Draft ||
        (modulesUnpublishedFlag && module.status === ProgramStatus.Draft)

      if (!showModule) {
        return null
      }

      const imageProps = {
        alignSelf: sm ? "center" : "flex-start",
        borderRadius: "$6",
        height: sm ? 110 : 138,
        width: sm ? 110 : 234,
        maxWidth: "100%",
        source: {
          uri: sm ? module.thumbnailUrl : module.imageUrl,
          height: sm ? 110 : 138,
          width: sm ? 110 : 234,
        },
      } satisfies ImageProps

      const placeholderProps = {
        height: sm ? 110 : 138,
        width: sm ? 110 : 234,
      }

      return (
        <View
          key={module.id}
          width={
            sm ? "100%"
            : md ?
              "$12"
            : lg ?
              "$11"
            : "$10"
          }
          minWidth={sm ? "auto" : 260}
          maxWidth="100%"
          testID="program-card"
        >
          <Pressable
            onPress={() => {
              trackButtonClicked("ProgramListing", pageName, {
                module_id: module.id,
                module_name: module.name,
                position: index + 1,
                logged_out: !isAuthenticated,
              })
              router.push(Routes.createRouteForProgramDetails(module.path))
            }}
            style={({ pressed }) => [
              {
                backgroundColor:
                  pressed ? "$backgroundCardsPress"
                  : module.status === ProgramStatus.Draft ? "$backgroundWarningLight"
                  : "transparent",
              },
            ]}
          >
            <Stack
              borderRadius={sm ? "$0" : "$8"}
              flexDirection={sm ? "row" : "column"}
              flexBasis={index === modules.length - 1 && !sm ? "100%" : "auto"}
              maxWidth="100%"
              overflow="hidden"
              padding="$sm"
              $sm={{ paddingHorizontal: "$0" }}
              transition="background-color 0.4s ease"
              width={sm ? "100%" : 260}
              backgroundColor={
                module.status === ProgramStatus.Draft ? "$backgroundWarningLight" : "$background"
              }
              hoverStyle={{
                cursor: "pointer",
                backgroundColor: "$backgroundCardsHover",
              }}
            >
              <View position="relative" height={sm ? 110 : 138} width={sm ? 110 : 234}>
                <ImageWithPlaceholder
                  key={module.id}
                  index={index}
                  onLoad={(idx) => handleImageLoad(idx ?? 0)}
                  previousImageLoaded={index === 0 || loadedImages[index - 1]}
                  imageProps={imageProps}
                  placeholderProps={placeholderProps}
                />

                <SubscribedImageBanner moduleId={module.id} />
              </View>

              <View
                marginTop={sm ? 0 : "$sm"}
                flexShrink={1}
                alignSelf={sm ? "center" : "flex-start"}
                $sm={{ marginLeft: "$base" }}
              >
                <H6 fontSize="$4" fontWeight={700} numberOfLines={2}>
                  {module.name}
                </H6>

                <SizableText
                  marginTop="$xxs"
                  fontSize="$4"
                  lineHeight="$3"
                  fontWeight={500}
                  numberOfLines={2}
                >
                  {module.description}
                </SizableText>
              </View>
            </Stack>
          </Pressable>

          {sm && index !== modules.length - 1 && <Separator />}
        </View>
      )
    })
  }, [modules, sm, md, lg, modulesUnpublishedFlag, loadedImages, router])

  return (
    <Stack alignItems={isAuthenticated ? "flex-start" : "center"} width="100%" paddingBottom="$xl">
      <Stack
        flexDirection={sm ? "column" : "row"}
        flexWrap="wrap"
        gap={sm ? "$0" : "$xs"}
        justifyContent={isAuthenticated ? "flex-start" : "center"}
        marginHorizontal={style.marginHorizontal}
        width={style.width}
        maxWidth={style.maxWidth}
        paddingHorizontal={!isAuthenticated && sm ? "$sm" : undefined}
      >
        {isLoading || !modules.length ?
          loaderList.map((value, index) => renderLoader(index))
        : renderedModules}

        {/* Add spacer blocks to ensure last line is left-aligned. */}
        {loaderList.map((item, index) => (
          <View
            key={index}
            width={
              sm ? "100%"
              : md ?
                "$12"
              : lg ?
                "$11"
              : "$10"
            }
            minWidth={sm ? "auto" : 260}
            maxWidth="100%"
          />
        ))}
      </Stack>
    </Stack>
  )
}
